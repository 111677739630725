import { AdminPanelHeader, DashBoardHeader, SideBar } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setAlerts, setLocations } from 'redux/Slices';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { CommonAlert, CommonBorderBox, Loader, PostHeader, QuestionsTab } from 'whealth-core-web/components';
import { dateFormatStringSingleDate, profileImage } from 'whealth-core-web/components/Helper';
import AddPatientForm from 'whealth-core-web/forms/AddPatientForm';

function CreatePatient() {
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const { id } = useParams();
  const { patientDetails } = useSelector((state) => state.sliceReducer);
  const dispatch = useDispatch();
  let userLocalData = localStorage.getItem('userDetails');
  userLocalData = JSON.parse(userLocalData);

  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-danger');
  const [errors, serErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const location = useLocation();

  const callLog = location?.state?.key;
  const [status, setStatus] = useState({
    link: !callLog || location?.state?.key === 'appointment' ? 'patient_profile' : 'profile_questions',
  });

  const [isNivanCare, setIsNivanCare] = useState(false);

  useEffect(() => {
    setUserDetails({ ...patientDetails, full_name: location?.state?.name });
  }, [location?.state?.name]);

  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'enable_summary' && item.isActive) {
          setIsNivanCare(item.isActive);
        }
      });
    }
  }, [configData]);

  useEffect(() => {
    if (id) {
      if (Object.keys(patientDetails).length > 0) {
        setUserDetails(patientDetails);
      } else {
        getPatientData();
      }
    } else {
      setUserDetails({});
    }
  }, [id, patientDetails]);

  const getPatientData = () => {
    setIsLoading(true);
    ApiClient.getPatientProfile(id)
      .then((res) => {
        setIsLoading(false);
        let newuserData = { ...res.data };
        newuserData.status_uuid = res.data.status.uuid;
        let tempLocations = [];
        newuserData?.locations?.map((item) => tempLocations.push(item.id));
        let reformattedData = { ...newuserData, locations: tempLocations };
        reformattedData.care_manager_id = reformattedData?.care_manager?.id;
        reformattedData.care_plan_id = reformattedData?.care_plan?.id;
        setUserDetails(reformattedData);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // const sideBar = useMemo(() => {
  //   return <SideBar />;
  // }, []);

  const showTree = id
    ? [
        {
          iconClass: 'person_outline',
          title: 'Patient',
          link: '/Patient',
        },
        {
          title: userLocalData?.name,
          link: `/patient/${id}/dashboard`,
          icon: profileImage(userLocalData?.gender, userLocalData?.image),
        },
        {
          title: 'Update Patient',
        },
      ]
    : [
        {
          iconClass: 'person_outline',
          title: 'Patient',
          link: '/Patient',
        },
        {
          title: 'Create Patient',
        },
      ];

  // const leftSideData = [
  //   { title: !id ? "Create Patient" : "Update patient", style: { color: "var(--themeDarkColor)" } },
  // ];

  const renderAdminPanelHeader = useMemo(() => {
    return <AdminPanelHeader showTree={showTree} iconTitle='person_outline' title='Patient' />;
  }, []);

  // const renderDashBoardHeader = useMemo(() => {
  //   return <DashBoardHeader leftData={leftSideData} />;
  // }, []);

  useEffect(() => {
    const getData = async () => {
      const res = await ApiClient.searchLocations();
      if (res.status == 200) {
        let locationsData = [];
        res.data.map((item) => locationsData.push({ id: item.id, title: item.name }));
        dispatch(setLocations(locationsData));
      }
    };
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);
  console.warn('loc', location);
  const createPatient = async (formData) => {
    setIsLoading(true);
    const newFormData = new FormData();
    newFormData.append('full_name', formData.full_name || '');
    newFormData.append('gender', formData.gender || '');
    newFormData.append('personality_type', formData.personality_type || '');
    newFormData.append('preferred_language', formData.preferred_language || '');
    newFormData.append('city', formData.city || '');
    newFormData.append('phone', formData.phone || '');
    newFormData.append('email', formData.email || '');
    newFormData.append('pincode', formData?.pincode || '');
    newFormData.append('state', formData?.state || '');
    newFormData.append('address', formData?.address || '');
    newFormData.append('date_of_birth', formData.date_of_birth || '');
    newFormData.append('care_plan_id', formData.care_plan_id || '');
    newFormData.append(
      'c_plan_activation_date',
      formData.c_plan_activation_date || dateFormatStringSingleDate(new Date())
    );
    newFormData.append('vendor', formData.vendor || '');
    newFormData.append('plan_status', formData.plan_status || '');
    if (isNivanCare && formData.locations)
      formData.locations?.map((item) => {
        newFormData.append('location_ids', item);
      });

    formData.profile_url &&
      typeof formData.profile_url != 'string' &&
      newFormData.append('profile_url', formData.profile_url);

    if (id) {
      let shouldUpdateDb = true;
      if (
        userDetails.address &&
        userDetails.state &&
        userDetails.city &&
        userDetails.pincode &&
        !formData.city &&
        !formData.state &&
        !formData.pincode &&
        !formData.address
      ) {
        shouldUpdateDb = false;
        setIsLoading(false);
        alert("The address fields can't all be empty");
      }

      if (shouldUpdateDb) {
        ApiClient.updatePatient(newFormData, id)
          .then(async (res) => {
            setIsLoading(false);
            navigate(`/patient/${id}/dashboard`);
            dispatch(setAlerts({ patient: { title: 'Patient Updated Successfully', type: 'alert-success' } }));
          })
          .catch((err) => {
            setIsLoading(false);
            if (err.response.status == 413) {
              setShowAlert('image size is too large');
            } else if (err.response.status == 500) {
              setShowAlert('Internal server Error');
            } else {
              err?.response?.data?.detail && setShowAlert(err?.response?.data?.detail);
              serErrors(err.response.data.errors);
            }
            setShowAlert(err.response.data.detail);
          });
      }
    } else {
      ApiClient.createPatient(newFormData)
        .then((res) => {
          setIsLoading(false);
          if (location?.state?.key === 'appointment') {
            navigate(`/appointment`, { state: { key: 'comingPatient', patientName: res?.data?.id } });
          } else {
            navigate('/patient');
          }
          dispatch(setAlerts({ patient: { title: 'Patient created successfully', type: 'alert-success' } }));
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          if (err.response.status == 413) {
            setShowAlert('image size is too large');
          } else if (err.response.status == 500) {
            setShowAlert('Internal server Error');
          } else {
            err?.response?.data?.detail && setShowAlert(err?.response?.data?.detail);
            serErrors(err.response.data.errors);
          }
        });
    }
  };

  const renderPageHeader = useMemo(() => {
    const postLinks = [
      {
        title: !id ? 'Create Patient' : 'Update patient',
        link: 'patient_profile',
      },
    ];

    // if (id) {
    //   postLinks.push({
    //     title: `Profile Questions`,
    //     link: 'profile_questions',
    //   });
    // }
    return (
      <div>
        <PostHeader
          callLog={callLog}
          postLinks={postLinks}
          onClickHandle={(data) => {
            setStatus(data);
          }}
        />
        <div className='hrLine mt-4' />
      </div>
    );
  }, []);

  const renderCreatePost = () => {
    return (
      <div className='px-4 wrapperContent'>
        <AddPatientForm
          getPatientData={getPatientData}
          setShowAlert={setShowAlert}
          setAlertType={setAlertType}
          patientData={userDetails}
          errors={errors}
          createPatient={createPatient}
          id={id}
        />
      </div>
    );
  };

  const renderQuestionsTab = () => {
    return (
      <div className='px-4 wrapperContent'>
        <CommonBorderBox className='d-flex justify-content-between teamBorderBox'>
          <div className='w-100'>
            <QuestionsTab data={userDetails} />
          </div>
        </CommonBorderBox>
      </div>
    );
  };

  const renderBody = () => {
    const renderDataObj = {
      patient_profile: renderCreatePost,
      profile_questions: renderQuestionsTab,
    };

    return (
      <>
        <div className='headerFiles articleHeader stickyHeader'>
          {renderAdminPanelHeader}
          {showAlert && (
            <div className='mt-2'>
              <CommonAlert isShowAlert={showAlert} alertType={alertType} setIsShowAlert={setShowAlert} />
            </div>
          )}
          {renderPageHeader}
        </div>
        {renderDataObj[status.link]()}
      </>
    );
  };

  return (
    <div>
      <div>
        {/* {sideBar} */}
        {renderBody()}
      </div>
      <Loader show={isLoading} />
    </div>
  );
}

export default CreatePatient;
