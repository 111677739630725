import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useGetLoginUserDataQuery } from "redux/apiSlice";
import { useGetCockpitDataQuery } from "redux/cockpitSlice";
import { plainLogo, loginpageLogo } from "res/images";
import { Dropdown } from "rsuite";
import { user } from "whealth-core-web/assets/images";
import { s3Url } from "whealth-core-web/components/Helper";

function SideBar(props) {
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState("");
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  // const sidebarImage = sideBarOpen ? sideMenuSwitcher : sideMenuSwitcherExp;
  //const sideBarClass = sideBarOpen ? 'sidebar-active' : 'sidebar-close';
  const isManageInventory = pathname === "/manage-inventory";

  useEffect(() => {
    if (isManageInventory) {
      setIsFirstVisit(true);
    } else {
      setIsFirstVisit(false);
    }
  }, [pathname]);

  const sideBarClass = isManageInventory
    ? isFirstVisit
      ? "sidebar-active"
      : sideBarOpen
      ? "sidebar-close"
      : "sidebar-active"
    : sideBarOpen
    ? "sidebar-active"
    : "sidebar-close";

  const getCockpitData = useGetCockpitDataQuery();
  let userData = useGetLoginUserDataQuery()?.data?.data || {};

  let configData = getCockpitData?.data?.data?.business_config;

  const linksData = [
    {
      icon: "home",
      title: "Home",
      link: "/dashboard",
      key: "home",
    },
    {
      showDropdown: true,
      dropDown: {
        title: "Patient",
        icon: "person",
        data: [
          {
            icon: "person",
            title: "Patient",
            link: "/patient",
            key: "patient",
          },
          {
            icon: "segment",
            title: "Segments",
            link: "/segments",
            key: "segment",
            condition: "enable_segment",
            hide: userData.role == "operator",
          },
        ],
      },
    },
    {
      icon: "calendar_month",
      title: "Appointment",
      link: "/appointment",
      key: "appointment",
      condition: "enable_appointment",
      hide: userData.role == "operator",
    },
    {
      icon: "shopping_bag",
      title: "Sales",
      link: "/sales",
      key: "sales",
    },
    {
      icon: "description",
      title: "Article",
      link: "/articles",
      key: "article",
      hide: userData.role == "operator",
    },
    {
      icon: "people_alt",
      title: "Team",
      link: "/team",
      key: "team",
      hide: userData.role == "operator",
    },
    {
      icon: "settings",
      title: "Settings",
      link: "/settings",
      key: "settings",
      condition: "enable_settings",
      hide:
        userData.role == "diet_health_coach" ||
        userData.role == "fitness_health_coach" ||
        userData.role == "doctor" ||
        userData.role == "health_coach" ||
        userData.role == "operator",
    },
    {
      icon: "notifications",
      title: "Notifications",
      link: "/notifications",
      key: "notifications",
      condition: "side_bar_notification",
      hide: userData.role == "operator",
    },
    {
      icon: "analytics",
      title: "Reports",
      link: "/reports",
      key: "side_bar_report",
      condition: "side_bar_report",
      hide: userData.role == "operator",
    },
    {
      icon: "shopping_cart",
      title: "Orders",
      link: "/orders",
      key: "orders",
      hide:
        configData?.findIndex(
          (item) => item.key == "enable_order" && item.isActive
        ) == -1,
    },
    {
      hide: true,
      icon: "manage_accounts",
      title: "Cockpit",
      link: "/cockpit",
      key: "cockpit",
    },
  ];

  let renderLink = (item, index) => {
    return (
      <NavLink
        key={index}
        to={item.link}
        className={({ isActive }) => {
          if (isActive) {
            setActiveLink(item.link);
            setBodyClass();
            return "sideBarText activeLink";
          } else {
            return "sideBarText";
          }
        }}
      >
        <div className="d-flex sideLinks">
          {/* <img src={icon} className="sidebarIcon" /> */}
          <span class="material-icons">{item.icon}</span>
          <div className="sidebarNavText">{item.title} </div>
        </div>
      </NavLink>
    );
  };

  const renderMenuItem = (data) => {
    let filterData = [];
    data.data.map((item, index) => {
      let isShow = false;
      if (configData && Array.isArray(configData)) {
        if (item.condition) {
          configData.map((confItem) => {
            if (confItem.key == item.condition && confItem.isActive) {
              isShow = true;
            }
          });
        } else {
          isShow = true;
        }
        if (userData?.role == "operator" && item.key == "segment") {
          isShow = false;
        }
      }
      isShow && filterData.push(item);
    });

    if (filterData.length == 1) {
      return renderLink(filterData[0], 1);
    } else if (!filterData.length) {
      return renderLink(data.data[0], 1);
    } else {
      return (
        <div className="d-flex sideLinks sideLinks-submenu">
          <span class="material-icons">{data.icon}</span>
          <Dropdown title={data.title}>
            {filterData.map((item, index) => {
              return renderLink(item, index);
            })}
          </Dropdown>
        </div>
      );
    }
  };

  const setBodyClass = () => {
    if (sideBarOpen) {
      document.body.classList.remove("bodyActive");
    } else {
      document.body.classList.add("bodyActive");
    }
  };

  const renderLinks = useMemo(() => {
    return linksData.map((item, index) => {
      if (item.hide) {
        return;
      }

      let returnEmpty = false;
      if (configData && Array.isArray(configData)) {
        configData.map((confItem) => {
          if (confItem.key == item.condition && !confItem.isActive) {
            returnEmpty = true;
          }
        });
      }
      if (returnEmpty) {
        return;
      }
      if (item.hideField) {
        return;
      }
      if (item.showDropdown && item.dropDown.data) {
        return renderMenuItem(item.dropDown);
      }
      return renderLink(item, index);
    });
  }, [activeLink, sideBarOpen, configData, userData]);

  const navBarLogo = useMemo(() => {
    return (
      <div className="d-flex flex-column sidebarLogo">
        <img src={s3Url("logo.svg", loginpageLogo)} className="logo" />
        <img
          src={s3Url("dash-short-logo.svg", plainLogo)}
          className="sm-logo"
        />
      </div>
    );
  });

  return (
    <div className={`articleListSideBar stickyPosition ${sideBarClass}`}>
      <div className="sidebarScreen">
        {navBarLogo}
        {renderLinks}
      </div>
      <div
        onClick={() => {
          setSideBarOpen(!sideBarOpen);
          setBodyClass();
          setIsFirstVisit(false);
        }}
        className="side_switcher_bar"
      >
        <span class="material-icons"></span>
      </div>
    </div>
  );
}

export default SideBar;
