import React, { useEffect, useState } from 'react';
import { CoreDropDown } from 'whealth-core-web/components';
import { Chart } from 'react-google-charts';
import { dateFormatString, prepareGraphData, subtractMonths } from 'whealth-core-web/components/Helper';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setRefreshWeightGraph } from 'redux/Slices';

function WeightCard({ title, graphData, showWeight, setWeight, dateValue, setDateValue, getGraphData }) {
  const [graphDataArr, setGraphDataArr] = useState([]);
  const { id } = useParams();
  const refreshWeightGraph  = useSelector((state) => state?.sliceReducer?.refreshWeightGraph);
  const dispatch = useDispatch();

  useEffect(() => {
    getGraphData();
  }, [dateValue, id]);
  
  useEffect(() => {
    setGraphDataArr(prepareGraphData(graphData?.plot_data?.plot_date));
    setWeight(graphData?.plot_data?.average);
  }, [graphData]);

  useEffect(() => {
    if(refreshWeightGraph){
      getGraphData();
      setGraphDataArr(prepareGraphData(graphData?.plot_data?.plot_date));
      setWeight(graphData?.plot_data?.average);
      dispatch(setRefreshWeightGraph(false));
    }
  }, [refreshWeightGraph]);

  // const showGraphOptions = () => {
  //   let endWeight = 0;
  //   let startWeight = 0;
  //   let startIndex = 0;
  // let options;
  // if (dateValue == 'months_1') {
  //   options = { day: 'numeric', month: 'short' };
  // } else {
  //   options = { month: 'short' };
  // }
  // let graphDataarr = [['Month', 'Weight', { role: 'style' }]];

  // if (graphData?.plot_date?.length > 0) {
  //   graphData.plot_date.map((item, index) => {
  //     let val = parseInt(item['1']);

  //     if (isNaN(val)) {
  //       startIndex += 1;
  //     }

  //     if (index == graphData.plot_date.length - 1) {
  //       endWeight = val;
  //     }
  //     if (index == startIndex) {
  //       startWeight = val;
  //     }

  // let month = new Date(item.record_date).toLocaleDateString(undefined, options);
  // let arr = [month, val, '#EB4B62'];
  // graphDataarr.push(arr);
  // });
  // } else {
  // let month = new Date(new Date()).toLocaleDateString(undefined, options);
  // let val = 0;
  // let arr = [month, val, '#EB4B62'];
  // graphDataarr.push(arr);
  // }
  // setGraphDataArr(graphDataarr);

  // console.log(startWeight);

  //   setWeight(endWeight - startWeight);
  // };

  const options = {
    legend: 'none',
    curveType: 'function',
    series: {
      0: { pointSize: 8 },
      1: { pointSize: 4 },
    },
    vAxis: {
      viewWindow: { min: 1 },
      baseline: 1,
      format: '#',
    },
    trendlines: {
      0: {
        type: 'exponential',
        visibleInLegend: true,
        color: 'red',
      },
    },
  };

  const durationData = [
    { title: '1 Year', id: dateFormatString(new Date(subtractMonths(11, new Date())).setDate(1)) },
    { title: '6 Months', id: dateFormatString(new Date(subtractMonths(5, new Date())).setDate(1)) },
    { title: '3 Months', id: dateFormatString(new Date(subtractMonths(2, new Date())).setDate(1)) },
    { title: '1 Month', id: dateFormatString(subtractMonths(1, new Date())) },
  ];

  return (
    <>
      <div className='d-md-flex mb-3'>
        <div className='card w-100 weight-card no-border'>
          <div className='card-body'>
            <div className='d-flex'>
              <div className='flex-grow-1'>
                <h5 className='card-title'> {title || 'Weight Graph'}</h5>
                <h6 className='card-title'>{showWeight ? parseFloat(showWeight).toFixed(1) + ' kg' : 'N/A'}</h6>
              </div>
              <div className='ms-auto'>
                <CoreDropDown value={dateValue} setValue={setDateValue} data={durationData} />
              </div>
            </div>
            <div className='d-flex'>
              {<Chart chartType='LineChart' data={graphDataArr} options={options} width='100%' />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WeightCard;
