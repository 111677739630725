const endPoints = {
  SEGMENT: (id) => {
    if (id) {
      return `segment/${id}/`;
    } else {
      return "segment/";
    }
  },
  SEGMENTCOUNT: (id) => `segment/${id}/count/`,
  SEGMENTEXPORT: (id) => `segment/${id}/export_to_csv/`,
  SEGMENTLISTING: (id) => `segment/${id}/listing/`,
  SEGMENTDATA: (id) => `segment/${id}/`,
  SEGMENTATTRIBUTES: "segment/attributes/",
  DASHBOARD: "admins/dashboard/",
  ARTICLES: "articles/",
  ARTICLECOUNT: "articles/count/",
  SENDOTP: "admins/send_otp/",
  VERIFYOTP: "admins/verify_otp/",
  ARTICLECATEGORY: "categories/",
  ARTICLETAGS: "tags/",
  ADMINPROFILE: (id) => (id ? `admin_profiles/${id}/` : "admin_profiles/"),
  UPDATEWORKINGHOUR: (id) => `working_slots/${id}/work_slot_update/`,
  USERPROFILE: "admins/profile/",
  ADMINPROFILECOUNT: "admin_profiles/count/",
  PATIENTCOUNT: "patients/count/",
  SEARCHTAGS: "tags/search/",
  SEARCATEGORIES: "categories/search/",
  SEARCHARTICLE: "articles/search/",
  SEARCHTEAM: "admin_profiles/search/",
  PATIENTSEARCH: "patients/search/",
  TASKSEARCH: "admins/assigned_tasks/search/",
  PATIENTLIST: "patients/",
  REFRESH_TOKEN: "token/refresh/",
  PROFILEQUESTIONS: "profile_questions/",
  MEASURINGEVENT: "measuring_events/",
  GOALCREATION: "goals/",
  GOALSEARCH: "goals/search/",
  UNASIGNADMIN: "unassign_admin/",
  ASIGNADMIN: "assign_admin/",
  MEDICINECATEGORIES: "medicine_categories/",
  MEDICINES: "medicines/",
  MEDICINESSEARCH: "medicines/search/",
  MEDICINECATEGORIESEARCH: "medicines/category_search/",
  PRESCRIPTIONS: "prescriptions/",
  AUDITLOG: "audit_logs/",
  DASHBOARDCONFIG: "admins/dashboard/config/",
  PATIENTPROFILETIMELINE: "timeline/",
  NOTES: "notes/",
  ASSIGNTASK: "admins/assigned_tasks/",
  REPORTTAB: "reports/",
  ADHERENCE: "adherence/",
  STATUS: "status/",
  DAYPART: "day_part/",
  DAYPARTSSEARCH: "day_part/search/",
  WEIGHTGRAPH: (id) => `patients/${id}/weight_graph/`,
  TASKS: (id) => `patients/${id}/tasks/`,
  TASKSCOMPLETE: (id, taskId) => `patients/${id}/tasks/${taskId}/completed/`,
  TASKSCOMPLETETOGGLE: (id, taskId) =>
    `patients/${id}/tasks/${taskId}/toggle_status/`,
  ACTIVITYLOGS: (id) => `patients/${id}/activity_logs/`,
  ACTIVITYLOGSONCHANGE: (id) => `patients/${id}/activity_logs/latest_activity/`,
  SEAECHTASKASSIGNTOSEARCH: (id) => `patients/${id}/tasks/assign_to_search/`,
  ACTIVEPRESCTIPTION: (id) => `patients/${id}/prescriptions/active/`,
  PATIENTGOALS: (id, goalId) => {
    if (goalId) {
      return `patients/${id}/goals/${goalId}/`;
    } else {
      return `patients/${id}/goals/`;
    }
  },
  GETPATIENTREPORTS: (id, reportid) => {
    if (reportid) {
      return `patients/${id}/reports/${reportid}/`;
    } else {
      return `patients/${id}/reports/`;
    }
  },
  PRESCRIPTIONSLIST: (id, pid) => {
    if (pid) {
      return `patients/${id}/prescriptions/${pid}/`;
    } else {
      return `patients/${id}/prescriptions/`;
    }
  },

  PRESCRIPTIONMEDICINE: (id, pid, mid) => {
    if (mid) {
      return `patients/${id}/prescriptions/${pid}/medicines/${mid}/`;
    } else {
      return `patients/${id}/prescriptions/${pid}/medicines/`;
    }
  },
  SUBSCRIPTION: (id) => `patients/${id}/subscriptions/extend/`,
  PRESCRIPTIONPDFDOWNLOAD: (id, pid) =>
    `/patients/${id}/prescriptions/${pid}/pdf/`,
  PROFILEANSWERS: (id) => `/patients/${id}/profile_answers/`,
  ACTIVITYLOGACTION: (id, logId) => `/patients/${id}/activity_logs/${logId}/`,
  BUSINESSCONFIGCOCKPIT: "admins/business_config/",
  // Manual Order
  MANUALORDER: "manual_order/",
  ADDUSERADDRESS: (id) => `users/user_address/${id}/`,
  UPDATEMANUALORDER: (id) => `manual_order/${id}/`,
  GETMANUALORDER: (id) => `manual_order/${id}`,
  GETALLORDER: "manual_order/",
  GENERATEPAYMENTLINK: (id) => `manual_order/${id}/generate_payment_link/`,
  GETPATIENTADDRESS: (id) => `patients/${id}/patient_address/`,
  ADDPATIENTADDRESS: (id) => `patients/${id}/patient_address/`,
  UPDATEPATIENTADDRESS: (id, addressId) =>
    `patients/${id}/patient_address/${addressId}/`,
  GETPATIENTPRESCRIPTIONITEMS: (id) =>
    `patients/${id}/patients_line_item_variation_presc/`,
  GETOPERATORLIST: "admin_profiles/return_operator_list",
  RECORDORDERPAYMENT: () => `payment/`,
  GENERICNOTES: () => `generic_note/`,
  GETNOTES: (id) => `generic_note?model_name=order&model_id=${id}`,
};

export default endPoints;
