import React, { useCallback, useState } from "react";
import "./drawer.scss"; // Create this for custom styles
import AddPlanForm from "whealth-core-web/forms/AddPlanForm";
import CloseIcon from "@mui/icons-material/Close";

function EditPlanDrawer({
  isOpen,
  setIsOpen,
  plan,
  setCurrentSelectedPlan,
  setInvalidateAPIKey,
}) {
  const toggleDrawer = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return (
    <div className="drawerContainer">
      <button onClick={toggleDrawer}>Toggle Drawer</button>

      <div className={`drawer ${isOpen ? "open" : ""}`}>
        <button
          onClick={() => {
            setCurrentSelectedPlan(null);
            toggleDrawer();
          }}
          // className="closeDrawer"
          style={{ position: "absolute", right: 20, top: 20, zIndex: 1000 }}
        >
          <CloseIcon />
        </button>
        <AddPlanForm
          closeForm={toggleDrawer}
          initialValues={plan}
          FormTitle="Edit Details"
          setInvalidateAPIKey={setInvalidateAPIKey}
        />
      </div>
      <div
        className={`overlay ${isOpen ? "active" : ""}`}
        onClick={toggleDrawer}
      ></div>
    </div>
  );
}

export default EditPlanDrawer;
