import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Chip,
  Tooltip,
  IconButton,
} from "@mui/material";
import { WhatsApp, MoreVert, Call } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "whealth-core-web/components/Helper";
import { render } from "preact/compat";
import { customerKeyMap } from "scenes/Sales";
import { CopyCheck } from "lucide-react";
import { toast } from "react-toastify";
import { formatDate } from "@fullcalendar/core";
const calculateWeightChange = (patient) => {
  const currentWeight = parseFloat(patient.health_matrix.current_weight);
  const startingWeight = parseFloat(patient.starting_weight);
  if (isNaN(currentWeight) || isNaN(startingWeight)) return "";
  return (currentWeight - startingWeight).toFixed(1);
};

const calculateTimeSinceLastWeightLog = (patient) => {
  const lastWeightLog = new Date(patient.last_weight_log_activity);
  const now = new Date();
  const diffHours = Math.floor((now - lastWeightLog) / (1000 * 60 * 60));
  return `${diffHours} Hours ago`;
};

const transformPatientData = (patient) => ({
  ...patient,
  language: patient.health_matrix.language || "",
  personality: patient.health_matrix.personality || patient.personality || "",
  program: patient.health_matrix.program || patient.program || "",
  weight: patient.weight || "",
  weight_change: calculateWeightChange(patient),
  weight_change_time: calculateTimeSinceLastWeightLog(patient),
  plan_status: patient.onboarding_status || "",
  call_status: patient.health_matrix.call_status || "N/A",
  lab_status: patient.health_matrix.lab_status || "",
});

const CustomerListing = ({ customers }) => {
  const navigate = useNavigate();

  const handleRowClick = (record) => {
    navigate(`/patient/${record.id}/dashboard`, { state: { data: record } });
  };
  const handleCopyLink = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        console.log("payment lin", link);
        toast.success("Payment link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const columns = [
    {
      title: "Customer",
      render: (record) => (
        <div
          className="flex items-center space-x-3"
          style={{ display: "flex", alignItems: "center", gap: "0.75rem" }}
        >
          <div
            className="flex-grow min-w-0"
            style={{ flexGrow: 1, minWidth: 0 }}
          >
            <div
              className="font-medium truncate"
              style={{
                fontWeight: 500,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {record.name}
              <div>{record.phone}</div>
            </div>

            <div
              style={{
                fontSize: "0.75rem",
                color: "#6B7280",
                display: "flex",
                alignItems: "center",
              }}
            >
              {record?.created_at && (
                <div className="bottomTextView">
                  Created on: {dateFormat(record?.created_at)}
                </div>
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Agent",
      render: (record) => {
        return <div>{record?.created_by?.full_name || ""}</div>;
      },
    },
    {
      title: "Customer Facing Notes",
      render: (record) => {
        return <div>{record.user_notes || ""}</div>;
      },
    },
    {
      title: "Internal Notes",
      render: (record) => <div>{record.admin_notes || ""}</div>,
    },
    {
      title: "Plan",
      render: (record) => (
        <div>
          <div>{record.plan.name || ""}</div>
        </div>
      ),
    },
    {
      title: "Plan Amount",
      render: (record) => (
        <div className="text-sm">Rs. {record.amount || ""}</div>
      ),
    },
    {
      title: "Status",
      render: (record) => {
        const isPaid = record.status.toLowerCase() === "paid";
        return (
          <div className="text-sm flex flex-col">
            <Chip
              label={customerKeyMap[record.status]}
              size="small"
              sx={{
                backgroundColor: isPaid ? "#F0FDF4" : "#AB1315",
                color: isPaid ? "#166534" : "white",
                borderRadius: "16px",
                padding: "2px 8px",
                "& .MuiChip-label": {
                  padding: 0,
                },
              }}
            />
            <div
              style={{
                color: "#6B7280",
                fontSize: "0.75rem",
                marginLeft: "8px",
              }}
            >
              {dateFormat(record.created_at)}
            </div>
          </div>
        );
      },
    },
    {
      title: "Amount Recieved",
      render: (record) => {
        const paymentMode = record.payment_mode.toLowerCase();
        const isCash = record.payment_mode.toLowerCase() === "cash";
        const haveNotPaid = record.status.toLowerCase() === "requested";
        const hasPaid = record.status.toLowerCase() === "paid";
        const paymentLink = record.payment_link;

        const getPaymentMode = () => {
          if (paymentMode === "cash") {
            return "Cash";
          }
          if (paymentMode === "razorpay") {
            const method = record.payment_details?.method;
            if (method === "card") {
              return "Card";
            }

            if (method === "netBanking") {
              return "Net Banking";
            }

            if (method === "upi") {
              return "UPI";
            }

            if (method === "emi") {
              const rate = record?.payment_details?.emi?.rate;
              const duration = record?.payment_details?.emi?.duration;
              return `EMI ${duration} Months @${rate / 100}%`;
            }
          }
        };
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 4,
              alignItems: "center",
            }}
          >
            <div>
              <div>Rs. {record.amount || ""}</div>
              <div style={{ color: "#6B7280", fontSize: "0.75rem" }}>
                {getPaymentMode()}
              </div>
              <div style={{ color: "#6B7280", fontSize: "0.75rem" }}>
                {dateFormat(record.updated_at)}
              </div>
            </div>
            {!isCash && haveNotPaid && (
              <CopyCheck
                size={30}
                color="white"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#243B86",
                  borderRadius: "10%",
                  padding: "5px",
                }}
                onClick={() => handleCopyLink(paymentLink)}
              />
            )}
          </div>
        );
        // return <div>{""}</div>;
      },
    },

    // {
    //   title: '',
    //   render: () => (
    //     <IconButton size='small'>
    //       <MoreVert className='text-gray-400' />
    //     </IconButton>
    //   ),
    // },
  ];

  return (
    <div style={{ marginBottom: "2.5rem" }}>
      <TableContainer component={Paper} className="overflow-x-auto">
        <Table className="min-w-full">
          <TableHead style={{ background: "#ebf0ff", fontWeight: "500" }}>
            <TableRow className="bg-gray-100">
              {columns.map((column, index) => (
                <TableCell key={index} className="font-medium text-gray-700">
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {customers?.map((patient) => (
              <TableRow
                key={patient.id}
                className="hover:bg-gray-100 cursor-pointer cursor transition-colors duration-150"
                style={{ transition: "background-color 0.15s ease-in-out" }}
              >
                {columns.map((column, index) => (
                  <TableCell key={index}>
                    {column.render
                      ? column.render(patient)
                      : patient[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CustomerListing;
