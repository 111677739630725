import React, { useEffect, useState } from 'react';
import { blueEdit, vitalsIcon1, vitalsIcon2, vitalsIcon3, vitalsIcon4, vitalsIcon5 } from 'res/images';
import { dateFormat, formatGenderAge, handleTextFormatting } from 'whealth-core-web/components/Helper';
import CommonBodyCard from '../CommonBodyCard';
import CoreInputBox from '../CoreInputBox';
import { Modal } from 'rsuite';
import useApiManager from 'networking/ApiManager';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPatientDetails } from 'redux/Slices';
import CoreTextArea from '../CoreTextArea';
import CoreToggleText from '../CoreToggleText';

function BodyCard(props) {
  const { data, title, items, hideBmi, getPatientData } = props;
  const [isWeightEdit, setWeightEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState();
  const [userDetails, setUserDetails] = useState({});
  const [isMedConEdit, setMedConEdit] = useState(false);
  const [metrics, setMetrics] = useState([
    {
      key: '',
      answer: '',
      profile_question_id: null,
    },
  ]);

  const ApiClient = useApiManager();
  const { id, pid } = useParams();
  // const dispatch = useDispatch();
  const patientDetails = useSelector((state) => state?.sliceReducer?.patientDetails);

  useEffect(() => {
    const extractMetrics = () => {
      const profileInfo = userDetails?.profile_info;
      const requiredKeys = ['weight', 'medical_condition', 'food_allergies', 'diet_intensity'];
      const extractedMetrics = profileInfo
        ?.filter((item) => requiredKeys?.includes(item.key))
        ?.map((item) => ({
          key: item.key,
          answer: item.answer,
          profile_question_id: item.profile_question_id,
        }));
      setMetrics(extractedMetrics);
    };

    extractMetrics();
  }, [userDetails]);

  useEffect(() => {
    if (id) {
      if (Object.keys(patientDetails).length > 0) {
        setUserDetails(patientDetails);
      } else {
        getPatientData();
      }
    } else {
      setUserDetails({});
    }
  }, [id, patientDetails]);

  const submitQuestionAnswers = () => {
    setIsLoading(true);
    const metricsList = metrics.map((metric) => ({
      question: metric.key,
      answer: metric.answer,
      profile_question_id: metric.profile_question_id,
    }));
    ApiClient.createProfileAnswers(id, metricsList)
      .then((res) => {
        setIsLoading(false);
        getPatientData();
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setErrors(err.response.data.errors);
      });
  };

  const getMetricAnswer = (key) => {
    const metric = metrics?.find((m) => m.key === key);
    return metric ? metric.answer : '';
  };

  const handleInputChange = (key, value) => {
    setMetrics((prevMetrics) =>
      prevMetrics?.map((metric) => (metric.key === key ? { ...metric, answer: value } : metric))
    );
  };

  if (!items) {
    return (
      <div className='d-md-flex mb-3 bodyCard'>
        <div className='card w-100 no-border'>
          <div className='card-body'>
            <div className='d-flex justify-content-between pb-2 mb-2'>
              <div className='checkupHead' style={{ color: '#243B86' }}>
                {title || 'Current Vitals'}
                <p className='created-updated'>updated on {dateFormat(data?.health_matrix?.last_activity)} </p>
              </div>
              <div className='light-black checkupTime'>
                <img
                  src={blueEdit}
                  onClick={() => {
                    setWeightEdit(true);
                  }}
                  className='editModal'
                />
                <Modal
                  backdrop='static'
                  size='md'
                  open={isWeightEdit}
                  onClose={() => {
                    getPatientData();
                    setWeightEdit(false);
                  }}
                >
                  <Modal.Header>
                    <div className='settingFormOutline p-0'>
                      <div className='formTitle mb-0' style={{ color: '#243B86' }}>
                        Edit Weight
                      </div>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='card-header d-flex justify-content-between align-items-center mb-4'>
                      <h6 className='card-title' style={{ color: 'black', fontWeight: '500' }}>
                        <b>{userDetails?.full_name} </b>
                        {formatGenderAge(userDetails?.gender, userDetails?.profile_age)}
                      </h6>
                    </div>
                    <CoreInputBox
                      validatedecimal
                      placeholder='weight'
                      label='Current Weight'
                      value={getMetricAnswer('weight') || ''}
                      className='w-100'
                      setValue={(inputVal) => {
                        handleInputChange('weight', inputVal);
                      }}
                      inputStyle={{ border: '1px solid #D1DCFF' }}
                    />
                    <h6 className='current-value ml-2 mt-2'>BMI {userDetails?.current_bmi}</h6>

                    <div className='mt-4' style={{ float: 'right' }}>
                      <button
                        className='cancel'
                        onClick={() => {
                          getPatientData();
                          setWeightEdit(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className='update'
                        onClick={() => {
                          setWeightEdit(false);
                          submitQuestionAnswers();
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
            <div className='d-flex checkup-data-part'>
              <div className='checkup-data d-flex w-100'>
                {!hideBmi ? (
                  <CommonBodyCard
                    // icon={vitalsIcon1}
                    label='BMI'
                    value={(data?.health_matrix?.current_bmi && data?.health_matrix.current_bmi) || ''}
                    subvalue={`(${data?.health_matrix?.bmi_status})`}
                  />
                ) : null}
                <CommonBodyCard
                  // icon={vitalsIcon2}
                  label='Weight'
                  value={
                    data.health_matrix?.current_weight
                      ? parseFloat(data.health_matrix?.current_weight).toFixed(1) + ' kg'
                      : ''
                  }
                />
                <CommonBodyCard
                  // icon={vitalsIcon3}
                  label='Height'
                  value={(data.health_matrix?.current_height && data.health_matrix?.current_height + ' cm') || ''}
                />

                {/* {data?.health_matrix?.current_sugar_level && (
                  <CommonBodyCard
                    icon={vitalsIcon4}
                    label='Sugar Level'
                    value={data.health_matrix?.current_sugar_level || 'N/A'}
                  />
                )}
                {data?.health_matrix?.current_blood_pressure && (
                  <CommonBodyCard
                    icon={vitalsIcon5}
                    label='Blood Pressure'
                    value={data.health_matrix?.current_blood_pressure || 'N/A'}
                  />
                )} */}
              </div>
            </div>
            <div className='border-bottom pb-2 mb-2'></div>
            <div className='d-flex justify-content-between'>
              <div className='checkupHead' style={{ color: '#243B86' }}>
                {title || 'Medical Conditions'}
              </div>
              <div className='light-black checkupTime'>
                <img
                  src={blueEdit}
                  onClick={() => {
                    setMedConEdit(true);
                  }}
                  className='editModal'
                />
              </div>
            </div>
            <div className='card-body'>
              <Modal
                backdrop='static'
                size='md'
                open={isMedConEdit}
                onClose={() => {
                  getPatientData();
                  setMedConEdit(false);
                }}
              >
                <Modal.Header>
                  <div className='settingFormOutline p-0'>
                    <div className='formTitle mb-0' style={{ color: '#243B86' }}>
                      Edit Medical Conditions
                    </div>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <CoreTextArea
                    type='text'
                    placeholder='medical Conditions'
                    label='Medical Conditions'
                    value={getMetricAnswer('medical_condition') || ''}
                    setValue={(inputVal) => {
                      handleInputChange('medical_condition', inputVal);
                    }}
                    handleKeyDown={(e) =>
                      handleTextFormatting(e, getMetricAnswer('medical_condition') || '', (updatedText) => {
                        handleInputChange('medical_condition', updatedText);
                      })
                    }
                    className='w-100 inputStyle'
                    inputStyle={{ border: '1px solid #D1DCFF' }}
                  />
                  <div className='mt-4' style={{ float: 'right' }}>
                    <button
                      className='cancel'
                      onClick={() => {
                        getPatientData();
                        setMedConEdit(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className='update'
                      onClick={() => {
                        setMedConEdit(false);
                        submitQuestionAnswers();
                      }}
                    >
                      Update
                    </button>
                  </div>
                </Modal.Body>
              </Modal>

              <div>
                <CoreToggleText text={getMetricAnswer('medical_condition') || ''} wordLimit={30} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    const renderDataMap = () => {
      return items.map((item, index) => {
        return (
          <CommonBodyCard
            items
            icon={item.icon}
            label={item.label}
            value={(data?.health_matrix && data.health_matrix[item.key] && data.health_matrix[item.key]) || ''}
          />
        );
      });
    };

    return (
      <div className='d-md-flex mb-3 bodyCard'>
        <div className='card w-100 no-border'>
          <div className='card-body'>
            <div className='d-flex justify-content-between border-bottom pb-2 mb-2'>
              <div className='checkupHead'>{title || 'Current Vitals'}</div>
              <div className='light-black checkupTime'>
                Last Update: {dateFormat(data?.health_matrix?.last_activity)}{' '}
              </div>
            </div>
            <div className='d-flex checkup-data-part'>
              <div className='checkup-data d-flex flex-column w-100'>{renderDataMap()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BodyCard;
