import React from "react";
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import useApiManager from "networking/ApiManager";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
const defaultValues = {
  name: "",
  type: "",
  description: "",
  plan_duration: "",
  max_price: "",
  min_price: "",
  status: "published",
  plan_items: [{ product: 0, product_name: "", quantity: "" }],
};
const AddPlanForm = ({
  initialValues = defaultValues,
  closeForm,
  FormTitle = "Create Plan",
  setInvalidateAPIKey,
}) => {
  const [planItems, setPlanItems] = useState([]);
  const ApiClient = useApiManager();

  const typeOptions = [
    { title: "Regular", value: "regular" },
    { title: "Renewal", value: "renewal" },
  ];

  const statusOptions = [
    { title: "Active", value: "published" },
    { title: "Inactive", value: "un_published" },
    // { title: "Draft", value: "draft" },
  ];

  const CreatePlanSuccessToast = ({ title, description }) => {
    return (
      <div>
        <p style={{ margin: 0, fontSize: "1rem" }}>{title}</p>
        <p style={{ margin: 0 }}>{description}</p>
      </div>
    );
  };
  const [formValues, setFormValues] = React.useState(initialValues);
  const handleServiceChange = (index, field, value) => {
    const updatedServices = [...formValues.plan_items];
    updatedServices[index][field] = value;
    setFormValues({ ...formValues, plan_items: updatedServices });
  };

  const addServiceGroup = () => {
    setFormValues({
      ...formValues,
      plan_items: [
        ...formValues.plan_items,
        { product: 0, product_name: "", quantity: "" },
      ],
    });
  };

  const removeServiceGroup = (index) => {
    console.log("index", index);
    const updatedServices = formValues.plan_items.filter((_, i) => i !== index);
    setFormValues({ ...formValues, plan_items: updatedServices });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (FormTitle === "Edit Details") {
      let finalFormValues = formValues;
      delete finalFormValues.updated_by;
      delete finalFormValues.created_by;
      delete finalFormValues.updated_at;
      delete finalFormValues.created_at;

      finalFormValues.plan_item?.map((item) => {
        const prodId = item.product.id;
        delete item.product;
        item.product = prodId;
      });

      ApiClient.updatePlan({ formValues: finalFormValues, id: formValues.id })
        .then((res) => {
          console.log(res);
          setInvalidateAPIKey((p) => p + 1);
          toast.success("Plan updated successfully");

          closeForm();
        })
        .catch((err) => {
          const errData = err.response.data;
          console.log("errData", errData);
          Object.values(errData).map((errMsg) => toast.error(errMsg[0]));
          console.log("failed to edit plan", err);
        });
    } else {
      ApiClient.createPlan(formValues)
        .then((res) => {
          console.log(res);
          setInvalidateAPIKey((p) => p + 1);
          // toast.success(`Plan ${formValues.name} created`);
          toast.success(
            <CreatePlanSuccessToast
              title={`Plan ${formValues.name} created`}
              description={formValues.description}
            />
          );

          setFormValues({
            name: "",
            type: "",
            description: "",
            plan_duration: "",
            max_price: "",
            min_price: "",
            status: "published",
            plan_items: [{ product: 0, product_name: "", quantity: "" }],
          });
          closeForm();
        })
        .catch((err) => {
          console.log("failed to create plan", err.response.data);
          const errData = err.response.data;
          const errIsArray = Array.isArray(errData);

          if (errIsArray) {
            errData?.map((el) => {
              Object.values(el).map((errMsg) => toast.error(errMsg[0]));
            });
          } else {
            Object.values(errData).map((errMsg) => toast.error(errMsg[0]));
          }
        });
    }
  };
  useEffect(() => {
    console.log("pre call get all plan items");
    ApiClient.getAllPlanItems()
      .then((res) => {
        console.log("all plan items", res);
        setPlanItems(res.data);
      })
      .catch((err) => {});
  }, []);
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 600,
        mx: "auto",
        p: 2,
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <Typography variant="h5" sx={{ mb: 2, textAlign: "center" }}>
        {FormTitle}
      </Typography>

      <TextField
        label="Name"
        name="name"
        value={formValues.name}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
        required
        InputProps={{
          sx: { fontSize: "0.875rem" }, // Smaller font size and adjusted height
        }}
        InputLabelProps={{
          sx: { fontSize: "0.75rem" }, // Smaller label font size
          children: (
            <>
              Name <span style={{ color: "red" }}>*</span>
            </>
          ),
        }}
      />

      <TextField
        label="Type"
        name="type"
        value={formValues.type}
        onChange={handleInputChange}
        select
        fullWidth
        margin="normal"
        variant="outlined"
        required
        InputProps={{
          sx: { fontSize: "0.875rem" },
        }}
        InputLabelProps={{
          sx: { fontSize: "0.75rem" },
          children: (
            <>
              Type <span style={{ color: "red" }}>*</span>
            </>
          ),
        }}
      >
        {typeOptions.map((option) => (
          <MenuItem key={option} value={option.value}>
            {option.title}
          </MenuItem>
        ))}
      </TextField>
      <Box
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          my: 2,
          backgroundColor: "#F5F7FF",
        }}
      >
        <Typography sx={{ fontSize: "0.75rem" }}>Services</Typography>
        {formValues.plan_items.map((service, index) => (
          <Box
            key={index}
            sx={{ display: "flex", gap: 1, alignItems: "center", mb: 2 }}
          >
            <TextField
              label="Service"
              name="service"
              value={service.product_name || ""}
              onChange={(e) => {
                const service = planItems?.find(
                  (item) => item.product_name === e.target.value
                );
                handleServiceChange(
                  index,
                  "product_name",
                  service.product_name
                );
                handleServiceChange(index, "product", service.id);
              }}
              select
              required
              fullWidth
              variant="outlined"
              InputProps={{
                sx: { fontSize: "0.875rem" }, // Smaller font size and adjusted height
              }}
              InputLabelProps={{
                sx: { fontSize: "0.75rem" },
              }}
            >
              {planItems?.map((option) => {
                return (
                  <MenuItem key={option} value={option.product_name}>
                    {option.product_name}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              label="Quantity"
              name="quantity"
              value={service.quantity}
              onChange={(e) =>
                handleServiceChange(index, "quantity", e.target.value)
              }
              type="number"
              fullWidth
              required
              variant="outlined"
              InputProps={{
                sx: { fontSize: "0.875rem" }, // Smaller font size and adjusted height
              }}
              InputLabelProps={{
                sx: { fontSize: "0.75rem" },
              }}
            ></TextField>

            <IconButton
              onClick={() => removeServiceGroup(index)}
              color="secondary"
              aria-label="remove service group"
            >
              <RemoveIcon />
            </IconButton>
          </Box>
        ))}
        <Button
          variant="outlined"
          color="primary"
          onClick={addServiceGroup}
          startIcon={<AddIcon />}
          sx={{ mb: 2 }}
        >
          Add another service
        </Button>
      </Box>

      <TextField
        label="Description"
        name="description"
        value={formValues.description}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
        variant="outlined"
        // required
        InputProps={{
          sx: { fontSize: "0.875rem" },
        }}
        InputLabelProps={{
          sx: { fontSize: "0.75rem" },
          children: (
            <>
              Description <span style={{ color: "red" }}>*</span>
            </>
          ),
        }}
      />

      <TextField
        label="Duration (Days)"
        type="number"
        name="plan_duration"
        value={formValues.plan_duration}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
        required
        InputProps={{
          sx: { fontSize: "0.875rem" },
        }}
        InputLabelProps={{
          sx: { fontSize: "0.75rem" },
        }}
      />

      <TextField
        label="Price"
        type="number"
        name="max_price"
        value={formValues.max_price}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
        required
        InputProps={{
          sx: { fontSize: "0.875rem" },
        }}
        InputLabelProps={{
          sx: { fontSize: "0.75rem" },
          children: (
            <>
              Price <span style={{ color: "red" }}>*</span>
            </>
          ),
        }}
      />

      <TextField
        label="Minimum Price"
        name="min_price"
        value={formValues.min_price}
        onChange={handleInputChange}
        fullWidth
        type="number"
        margin="normal"
        variant="outlined"
        required
        InputProps={{
          sx: { fontSize: "0.875rem" },
        }}
        InputLabelProps={{
          sx: { fontSize: "0.75rem" },
        }}
      />

      <TextField
        label="Status"
        name="status"
        defaultValue={"un_published"}
        value={formValues.status}
        onChange={handleInputChange}
        select
        fullWidth
        margin="normal"
        variant="outlined"
        // required
        InputProps={{
          sx: { fontSize: "0.875rem" },
        }}
        InputLabelProps={{
          sx: { fontSize: "0.75rem" },
          children: (
            <>
              Status <span style={{ color: "red" }}>*</span>
            </>
          ),
        }}
      >
        {statusOptions.map((option) => (
          <MenuItem key={option} value={option.value}>
            {option.title}
          </MenuItem>
        ))}
      </TextField>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
      >
        Submit
      </Button>
    </Box>
  );
};

export default AddPlanForm;
