import React, { useCallback, useState } from "react";
import "./drawer.scss"; // Create this for custom styles
import AddPlanForm from "whealth-core-web/forms/AddPlanForm";
import CloseIcon from "@mui/icons-material/Close";
import SellPlanForm from "whealth-core-web/forms/SellPlanForm";
function SellPlanDrawer({
  isOpen,
  setIsOpen,
  plans,
  setIsOpenRecordPayment,
  setRecordPaymentPayload,
  currentSelectedPlan,
  setCurrentSelectedPlan,
  setInvalidateAPIKey,
}) {
  const toggleDrawer = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return (
    <div className="drawerContainer">
      {/* <button onClick={toggleDrawer}>Toggle Drawer</button> */}

      <div className={`drawer ${isOpen ? "open" : ""}`}>
        <button
          onClick={() => {
            toggleDrawer();
            setCurrentSelectedPlan();
          }}
          // className="closeDrawer"
          style={{ position: "absolute", right: 20, top: 20, zIndex: 1000 }}
        >
          <CloseIcon />
        </button>
        <SellPlanForm
          plans={plans}
          setOpenRecordPaymentModal={setIsOpenRecordPayment}
          setRecordPaymentPayload={setRecordPaymentPayload}
          currentSelectedPlan={currentSelectedPlan}
          setCurrentSelectedPlan={setCurrentSelectedPlan}
          setInvalidateAPIKey={setInvalidateAPIKey}
          closeDrawer={toggleDrawer}
        />
      </div>
      <div
        className={`overlay ${isOpen ? "active" : ""}`}
        onClick={toggleDrawer}
      ></div>
    </div>
  );
}

export default SellPlanDrawer;
