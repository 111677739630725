import React from 'react';

const TextWithFormatting = ({ text }) => {
  const formattedText = text?.split('\n').map((line, index) => {
    const formattedLine = line
      ?.split('\t')
      .map((segment, i) => {
        return segment.replace(/\s/g, '&nbsp;'); // Replace spaces with &nbsp;
      })
      .join('&nbsp;&nbsp;&nbsp;&nbsp;'); // Handle tab spacing

    return (
      <React.Fragment key={index}>
        <span dangerouslySetInnerHTML={{ __html: formattedLine }} />
        <br />
      </React.Fragment>
    );
  });

  return <div>{formattedText}</div>;
};

export default TextWithFormatting;
